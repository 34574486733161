exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkorder-tsx": () => import("./../../../src/pages/checkorder.tsx" /* webpackChunkName: "component---src-pages-checkorder-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-orderhistory-tsx": () => import("./../../../src/pages/orderhistory.tsx" /* webpackChunkName: "component---src-pages-orderhistory-tsx" */),
  "component---src-pages-panel-logout-tsx": () => import("./../../../src/pages/panel/logout.tsx" /* webpackChunkName: "component---src-pages-panel-logout-tsx" */),
  "component---src-pages-payment-confirmation-tsx": () => import("./../../../src/pages/payment/confirmation.tsx" /* webpackChunkName: "component---src-pages-payment-confirmation-tsx" */),
  "component---src-pages-payment-transfer-tsx": () => import("./../../../src/pages/payment/transfer.tsx" /* webpackChunkName: "component---src-pages-payment-transfer-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-quality-tsx": () => import("./../../../src/pages/quality.tsx" /* webpackChunkName: "component---src-pages-quality-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-regulations-tsx": () => import("./../../../src/pages/regulations.tsx" /* webpackChunkName: "component---src-pages-regulations-tsx" */),
  "component---src-pages-reset-forget-tsx": () => import("./../../../src/pages/reset/forget.tsx" /* webpackChunkName: "component---src-pages-reset-forget-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset/password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-suplementy-dla-mezczyzn-tsx": () => import("./../../../src/pages/suplementy-dla-mezczyzn.tsx" /* webpackChunkName: "component---src-pages-suplementy-dla-mezczyzn-tsx" */),
  "component---src-pages-suplementy-dla-sportowcow-tsx": () => import("./../../../src/pages/suplementy-dla-sportowcow.tsx" /* webpackChunkName: "component---src-pages-suplementy-dla-sportowcow-tsx" */),
  "component---src-pages-suplementy-na-pamiec-tsx": () => import("./../../../src/pages/suplementy-na-pamiec.tsx" /* webpackChunkName: "component---src-pages-suplementy-na-pamiec-tsx" */),
  "component---src-pages-suplementy-na-wlosy-tsx": () => import("./../../../src/pages/suplementy-na-wlosy.tsx" /* webpackChunkName: "component---src-pages-suplementy-na-wlosy-tsx" */),
  "component---src-pages-suplementy-przy-dietach-tsx": () => import("./../../../src/pages/suplementy-przy-dietach.tsx" /* webpackChunkName: "component---src-pages-suplementy-przy-dietach-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-pages-userpanel-tsx": () => import("./../../../src/pages/userpanel.tsx" /* webpackChunkName: "component---src-pages-userpanel-tsx" */),
  "component---src-pages-yourorder-tsx": () => import("./../../../src/pages/yourorder.tsx" /* webpackChunkName: "component---src-pages-yourorder-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */)
}

